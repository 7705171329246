import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Outlet, useParams, useSearchParams, useLocation, useNavigate  } from "react-router-dom";
import {ConfigProvider, Button, Layout } from 'antd'
import Header from './Header'
import styles from './index.module.scss'
import MainContext from '@/context/MainContext'
import accountApi from '@/api/accountApi'
import { IntlProvider } from 'react-intl'
import messages from './messages'
import axios from 'axios'
import zhCN from 'antd/locale/zh_CN';
import enGB from 'antd/locale/en_GB'
import ruRu from 'antd/locale/ru_RU'
import zhHK from 'antd/locale/zh_HK'
import Tr from 'antd/locale/tr_TR'
import Th from 'antd/locale/th_TH'

let timer1: any = null
const reducer = (state: any, action: any) => {
  console.log('action',action)
  switch(action.type) {
    case 'userInfo':
      return {
        ...state,
        userInfo: action.value
      }
    default:
      return state
  }
}

const Main: React.FC<any> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams()
	const lang = searchParams.get("lang") || localStorage.getItem("lang") || 'zh'
  const { Content } = Layout
  let nim = useRef<any>(null)
  const { pathname, search } = useLocation()
	const [langMessage, setLangMessage] = useState<any>({})
  const [ store, setStore ] = useReducer(reducer, {
    userInfo: {}
  })
	
	const langTemp: any = {
	  'zh': {
	    locale: 'zh-CN',
	    antdLocale: zhCN
	  },
	  'en': {
	    locale: 'en',
	    antdLocale: enGB
	  },
	  'ru': {
	    locale: 'ru',
	    antdLocale: ruRu
	  },
	  'cht': {
	    locale: 'cht',
	    antdLocale: zhHK
	  },
	  'tr': {
	    locale: 'tr',
	    antdLocale: Tr
	  },
		'th': {
		  locale: 'th',
		  antdLocale: Th
		},
	}
	
	
  
  useEffect(()=>{
		if(lang) {
			localStorage.setItem("lang", lang)
		}
		let keys = Object.keys(messages)
		let params = {
			lang,
			keys: keys
		}
		/* axios.post("/v1/api/common/lang/pack", params).then((res: any) => {
			if(res.code === 1) {
				setLangMessage(res.data)
			}
		}) */
		
  },[lang])
  
  return (
		
    <MainContext.Provider value={{store, setStore}}>
      <div className={styles.container}>
				<IntlProvider locale={lang} messages={langMessage}>
        <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#096BED',
                borderRadius: 2
              },
            }}
            locale={langTemp[lang].antdLocale}
        >
          <Layout>
            <Header></Header>
            <Content style={{padding: 0}}>
              <div className={styles.content}>
                <Outlet></Outlet>
              </div>
            </Content>
          </Layout>
        </ConfigProvider>
				</IntlProvider>
				
        <audio src="https://cdns.007vin.com/img/voice_prompt.mp3" id="transfer-audio"></audio>
      </div>
    </MainContext.Provider>
		
  )
}

export default Main;
